import React from 'react'

import { YaMap } from '@components/organisms'

export const MainTemplate: React.FC = () => {
  return (
    <>
      <YaMap />
    </>
  )
}
